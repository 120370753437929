<template>
    <li class="feature" @mouseover="onMouseOver" @click.stop="onClick">
        <a v-if="url" :href="url" target="_blank" class="feature-reference-url" />
        <div class="feature-icon-wrapper-outer">
            <div class="feature-icon-wrapper-inner" :class="'circle-rating-' + rating">
                <div v-if="rating" class="feature-rating">
                    {{ rating }}
                    <span>&#47;10</span>
                </div>
                <div v-else class="feature-rating">
                    <span class="v-align-middle">no rating</span>
                </div>
            </div>
        </div>
        <div class="feature-details-wrapper">
            <h6 class="feature-header">{{ name }}</h6>
            <p v-if="address" class="feature-address">
                {{ address.street }}, {{ address.city }}, {{ address.state }}, {{ address.zipcode }}
            </p>
            <p class="feature-misc-details">
                {{ level }} School
                <span>, {{ grades }} |</span>
                {{ num_students }}
                <span>students</span>
            </p>
            <div class="feature-last-row">
                <p class="feature-distance">Distance: {{ distance }} miles</p>
                <img class="great-schools-logo" src="/images/great-schools-logo.png" alt="Great Schools" />
            </div>
        </div>
    </li>
</template>

<script>
import { get, debounce } from 'utils/lodash';
export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    computed: {
        url() {
            return get(this.row, 'properties.url');
        },
        rating() {
            return get(this.row, 'properties.rating');
        },
        name() {
            return get(this.row, 'properties.name');
        },
        distance() {
            return get(this.row, 'properties.distance');
        },
        level() {
            return get(this.row, 'properties.level');
        },
        grades() {
            return get(this.row, 'properties.grades');
        },
        num_students() {
            return get(this.row, 'properties.num_students');
        },
        address() {
            return get(this.row, 'properties.address', null);
        },
    },
    methods: {
        onMouseOver: debounce(function () {
            this.$emit('hover', this.row);
        }, 250),
        onClick() {
            this.$emit('click', this.row);
        },
    },
};
</script>
