<template>
    <ul>
        <school-block v-for="row in rows" :key="row.id" :row="row" @hover="onHover" @click="onClick" />
    </ul>
</template>

<script>
import http from 'services/axios';
import SchoolBlock from './components/block';
export default {
    components: {
        SchoolBlock,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        rows: [],
    }),
    created() {
        this.fetchSchools();
    },
    methods: {
        async fetchSchools() {
            let response = await http.get(this.layer.url);

            this.rows = response.data?.features;

            this.$emit('rows', this.rows);
        },
        onClick(row) {
            this.$emit('click', row);

            let inBounds = this.map.map.getBounds().contains(row.geometry.coordinates);

            if (!inBounds || this.map.map.getZoom() < 14) {
                this.map.map.flyTo({
                    center: row.geometry.coordinates,
                    zoom: 14,
                });
            }
        },
        onHover(row) {
            this.$events.$emit('map:popup:enter', {
                coordinates: row.geometry.coordinates,
                html: row.properties.name,
            });
        },
    },
};
</script>
